import { createSlice } from '@reduxjs/toolkit';

export const ModuleLanguage = createSlice({
  name: 'ModuleLanguage',
  initialState: {
    language: '',
  },
  reducers: {
    changeLanguage: (state, actions) => {
      state.language = actions.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeLanguage } = ModuleLanguage.actions;

export default ModuleLanguage.reducer;
