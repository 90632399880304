import './index.css';

import { localDateTR } from 'common/LocalDate';
import moment from 'moment';
import { addLocale } from 'primereact/api';
import { Calendar } from 'primereact/calendar';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { customUrlService } from 'Services/AxiosServices';

import SignatureHistoryDialog from './SignatureHistoryDialog';

function SignatureHistory() {
  const { t } = useTranslation();
  const [resStatus, setResStatus] = useState(200);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [selectedHistory, setSelectedHistory] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [filterDate, setFilterDate] = useState('');
  const [selectedRow, setSelectedRow] = useState();
  const [showDialog, setShowDialog] = useState(false);

  addLocale('tr-TR', localDateTR);

  useEffect(() => {
    let url;

    if (process.env.NODE_ENV == 'development') {
      url = 'https://account.dev.elit.gov.tr/api/audit?size=1000';
    } else {
      url = window.location.origin + '/api/audit?size=1000';
    }
    customUrlService(url, 'GET', null).then((res) => {
      setResStatus(res.status);
      if (res.status != 500) {
        setResStatus(res.status);

        const signatureHistoryFiltered = res.data.filter((x) => x.log.message == 'SAM.NEW_SIGNATURE');

        setTransactionHistory(signatureHistoryFiltered);
        setSelectedHistory(signatureHistoryFiltered);
      }
    });
  }, []);

  useEffect(() => {
    if (filterDate) {
      setSelectedHistory(transactionHistory.filter((x) => moment(x.timestamp).format('DD/MM/YYYY') == moment(filterDate).format('DD/MM/YYYY')));
    }
  }, [filterDate]);
  const tableHeader = () => (
    <div className="row justify-content-between">
      <div className="col-md-4 col-sm-12">
        <span className="p-input-icon-left w-100">
          <i className="pi pi-search" />
          <InputText className="w-100" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('searchTable')} />
        </span>
      </div>
      <div className="col-md-4 offset-md-4 col-sm-12 d-flex justify-content-end position-relative">
        {filterDate && (
          <svg className="transaction-date-svg" onClick={clearDate}>
            <use href="#search-close" />
          </svg>
        )}
        <Calendar
          className="w-100 calender-h"
          onClearButtonClick={(e) => setFilterDate(e.target.value)}
          placeholder={t('selectDate')}
          id="icon"
          dateFormat="dd/mm/yy"
          value={filterDate}
          onChange={(e) => changeDate(e)}
          showIcon
          locale={localStorage.getItem('lang') == 'tr-TR' ? 'tr-TR' : null}
        />
      </div>
    </div>
  );
  const timestampBody = (rowData) => (
    <div>
      <span>{moment(rowData.timestamp).format('DD/MM/YYYY HH:mm')}</span>
    </div>
  );
  const fileHashBody = (rowData) => (
    <div>
      <Tooltip target=".fileHash" position="top" />
      <span className="fileHash" data-pr-tooltip={rowData.log.hashInfos}>
        {rowData.log.hashInfos.slice(0, 20) + '...'}
      </span>
    </div>
  );
  const rowSelect = (e) => {
    setSelectedRow(e.value);
    setShowDialog(true);
  };
  const closeDialog = () => {
    setShowDialog(false);
  };
  const changeDate = (e) => {
    setFilterDate(e.target.value);
  };
  const clearDate = () => {
    setFilterDate('');
    setSelectedHistory(transactionHistory);
  };
  const messageBody = (rowData) => <span>{t(rowData.log.message)}</span>;

  return (
    <>
      <Card className="transaction-history-card">
        <div className="d-flex justify-content-between">
          <span className="my-personal-informations-title"> {t('signatureHistory')} </span>
        </div>
        <div>
          <DataTable
            selectionMode="single"
            value={selectedHistory}
            paginator
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate={t('currentPageReportTemplateFL')}
            className="p-datatable-customers"
            rows={10}
            dataKey="id"
            filterDisplay="menu"
            responsiveLayout="scroll"
            globalFilter={globalFilter}
            header={tableHeader}
            onSelectionChange={(e) => rowSelect(e)}
            emptyMessage={resStatus === 500 ? t('transactionTable500') : t('emptySignatureTable')}
            rowsPerPageOptions={[10, 20, 30]}
          >
            <Column sortable body={timestampBody} field="timestamp" header={t('operationTime')} />
            <Column field="ip" header={t('operationAdress')} />
            <Column field="log.message" body={messageBody} header={t('description')} />
            <Column body={fileHashBody} header={t('fileHash')} />
          </DataTable>
        </div>
      </Card>
      {showDialog && <SignatureHistoryDialog show={showDialog} closeDialog={closeDialog} selectedRow={selectedRow} />}
    </>
  );
}

export default SignatureHistory;
