import { configureStore } from '@reduxjs/toolkit';

import MobileSize from './MobileSize/MobileSize';
import ModuleLanguage from './ModuleLanguage/ModuleLanguage';

export default configureStore({
  reducer: {
    isMobileSize: MobileSize,
    ModuleLanguage,
  },
});
