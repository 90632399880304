import alertType from 'common/ToastColor';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const swal = withReactContent(Swal);

export function popup(icon, text, outsideClick, confirmText = null, cancelText = null, timer = 0) {
  return new Promise((resolve) => {
    swal
      .fire({
        icon: alertType[icon].icon,
        iconHtml: alertType[icon].iconSVG,
        // titleText: titleText || null,
        text,
        confirmButtonText: confirmText || '',
        cancelButtonText: cancelText || '',
        showConfirmButton: confirmText !== null,
        showCancelButton: cancelText !== null,
        timer: confirmText || cancelText ? false : timer,
        toast: false,
        timerProgressBar: true,
        allowOutsideClick: outsideClick || false,
        allowEscapeKey: false,
        allowEnterKey: false,
        customClass: {
          popup: 'modal-width',
          title: 'modal-title',
          htmlContainer: 'modal-container',
          actions: 'modal-actions',
        },
      })
      .then((res) => {
        resolve(res.isConfirmed);
      });
  });
}
/** Sweetalert2 toast bildirimi açar */
export function toast(titleText, text, type) {
  swal.fire({
    titleText: titleText || 'Başarılı!',
    text: text || 'İşlem Başarılı!',
    position: 'bottom-right',
    iconHtml: alertType[type].iconSVG,
    icon: alertType[type].icon,
    color: alertType[type].color,
    background: alertType[type].background,
    width: '500px',
    timer: 3000,
    toast: true,
    timerProgressBar: true,
    showConfirmButton: false,
    showCloseButton: true,
  });
}
