import axios from 'axios';
import i18n from 'i18next';

import { toast } from './AlertServices';

// axios.defaults.baseURL = process.env.REACT_APP_BASE_ENDPOINT + '/';
axios.defaults.baseURL = window.location.origin;
axios.defaults.headers.common.Accept = 'application/json; charset=utf-8';
axios.defaults.headers.common['Content-Type'] = 'application/json';

axios.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem('JWT-Token');

    if (jwtToken) {
      config.headers.Authorization = `Bearer ${jwtToken}`;
    }

    // Gönderilecek veriden HTML tag'leri, istenmeyen karakterler vs temizlenir (sanitization).
    // config = InputUtility.sanitizeData(config);
    return config;
  },

  (error) => Promise.reject(error),
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location.reload();
    } else if (error.response && error.response.status === 403) {
      localStorage.clear();
      window.location.href = '/login?warning=true';
    } else if (error.response && error.response.status === 400) {
      toast('Uyarı!', error.response.data.detail, 'warning');
    } else if (error.response && error.response.status === 500) {
      toast(i18n.t('500text'), i18n.t('500title'), 'error');
    }

    return Promise.reject(error);
  },
);

export function axiosServicesPost(url, data, showToast, title, titleMesaj) {
  return axios

    .post(url, data)
    .then((response) => {
      // handle success
      if (showToast) {
        toast(title, titleMesaj, 'success');
      }

      return response;
    })
    .catch(
      (error) =>
        // handle error
        error.response,
    );
}

export function axiosServicesGet(url, data, showToast, title, titleMesaj) {
  return axios
    .get(url, data)
    .then((response) => {
      // handle success
      if (showToast) {
        toast(title, titleMesaj, 'success');
      }

      return response;
    })

    .catch(
      (error) =>
        // handle error
        error.response,
    );
}

export function axiosServicesDelete(url, data, showToast, title, titleMesaj) {
  return axios
    .delete(url, data)
    .then((response) => {
      // handle success
      if (showToast) {
        toast(title, titleMesaj, 'success');
      }

      return response;
    })

    .catch(
      (error) =>
        // handle error
        error.response,
    );
}

export function axiosServicesPut(url, data, showToast, title, titleMesaj) {
  return axios
    .put(url, data)
    .then((response) => {
      // handle success
      if (showToast) {
        toast(title, titleMesaj, 'success');
      }

      return response;
    })

    .catch(
      (error) =>
        // handle error
        error.response,
    );
}

export async function customUrlService(url, method, data, headers) {
  return axios({
    method,
    url,
    data,
    headers,
  })
    .then((response) => response)
    .catch((error) => error.response);
}
