const EN = {
  translation: {
    menu: 'Menu',
    profile: 'Profile',
    certificate: 'Certificates',
    certificateSubTitle: 'On this page you can view the signing certificates you have.',
    transactionHistory: 'Transaction History',
    transactionHistoryText: 'On this page, you can save your preferences and view your activities.',
    myPersonalInformations: 'Personal Informations',
    nameSurname: 'Name-Surname',
    phoneNumber: 'Phone Number',
    ePosta: 'E-Mail',
    sms: 'SMS',
    changePassword: 'Change Password',
    twoFactorAuthenticationPreference: 'Two-Factor Authentication Preference ',
    twoFactorAuthenticationPreferenceText:
      'Two-Factor Authentication (2FA) is a security measure used to confirm user identity. A password is sent by the system to the mobile phone or e-mail that only the user can access, apart from the account password, for proof of identity.',
    smsEpostaInfo:
      'Depending on the selection above, you must enter the security code that will come from SMS or e-mail, on the screen that appears after you enter your password to log in.',
    myCertificates: 'Certificates',
    seeAll: ' See All',
    totalNumberofCertificates: 'Total Number of Certificates',
    numberOfCertificatesInTheArchive: 'Number of Certificates in the Archive',
    numberOfValidCertificates: 'Number of Valid Certificates',
    numberOfInvalidCertificates: 'Number of Invalid Certificates',
    headerText: 'Profile',
    transactionType: 'Transaction Type',
    transactionHour: 'Transaction Hour',
    transactionIp: 'Transaction IP',
    transactionDesc: 'Transaction Description',
    transactionText: 'On this page, you can view the transactions you have performed before.',
    searchForCertificate: 'Search for Certificate...',
    lastLoginDay: 'Last login date:',
    lastLoginHour: 'Last login hour:',
    logout: 'Sign Out',
    certificateTitle: 'Certificate Detail',
    helloText: 'Hello',
    // TransactionHistory Start
    operationTime: 'Operation Time',
    level: 'Operation Type',
    operationAdress: 'Operation Adress',
    operationDevice: 'Operation Device',
    description: 'Description',
    selectTableRow: 'Select Number of Logs',
    infolevel: 'Info',
    warnlevel: 'Warn',
    signature: 'Signature',
    otherlevel: 'Other',
    selectDate: 'Select Date',
    searchTable: 'Search Table',
    hello: 'Hello',
    lastLogin: 'Last Successful login',
    transactionDetail: 'Transaction Detail',
    transactionStatus: 'Transaction Type',
    transactionDate: 'Transaction Date',
    transactionDevice: 'Transaction Device',
    instanceId: 'Instance Id: ',
    spanId: 'Span Id: ',
    traceId: 'Trace Id: ',
    appVersion: 'Application Version: ',
    emptyTransactionTable: 'No transaction history found',
    emptySignatureTable: 'No signature history found',
    transactionTable500: 'Transaction history cannot be viewed at this time. Please try again later.',
    user: 'User',
    name: 'Name',
    currentPageReportTemplateFL: '{first} - {last} / {totalRecords} Showing.',
    signatureHistory: 'Signature History',
    fileHash: 'File Hash',
    'SAM.NEW_SIGNATURE': 'New signature created',
    'CMS.NEW_USER_CREATED': 'User account created.',
    'CMS.NEW_CERTIFICATE': 'New certificate created.',
    'CMS.CERT_STATUS_CHANGED': 'Certificate status has been changed. ',
    'KCS.NEW_KEY_PAIR': 'New key pair created.',
    transaction_login: 'Account logged in successfully.',
    transaction_logout: 'The account was successfully logged out.',
    transaction_login_error: 'An error occurred while logging into the account.',
    // TransactionHistory End
    '500title': 'Try again!',
    '500text': 'There is something wrong!',
    app: 'Application',
    otpTitle: 'Are you sure you want to make this change?',
    cancel: 'Cancel',
    save: 'Save',
    message: 'Message',
    LOGIN: 'Login',
    LOGOUT: 'Logout',
    AUDIT: 'Audit',
    UPDATE: 'Update',
    LOGIN_ERROR: 'Login Error',
    UPDATE_PASSWORD: 'Update Password',
    CREATE: 'Create',
    UPDATE_PASSWORD_ERROR: 'Update Password Error',
  },
};

export default EN;
