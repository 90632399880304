const TR = {
  translation: {
    menu: 'Menü',
    profile: 'Profilim',
    certificate: 'Sertifikalarım',
    certificateSubTitle: 'Bu sayfada sahip olduğunuz imzalama sertifikalarını görüntüleyebilirsiniz.',
    transactionHistory: 'İşlem Geçmişi',
    transactionHistoryText: 'Bu sayfada tercihlerinizi kaydedebilir ve hareketlerinizi görüntüleyebilirsiniz.',
    myPersonalInformations: 'Kişisel Bilgilerim',
    nameSurname: 'Ad-Soyad',
    phoneNumber: 'Telefon Numarası',
    ePosta: 'E-Posta',
    sms: 'SMS',
    changePassword: 'Parolayı Değiştir',
    twoFactorAuthenticationPreference: 'İki Faktörlü Doğrulama Tercihi',
    twoFactorAuthenticationPreferenceText:
      'İki Faktörlü Doğrulama (2FA), kullanıcı kimliğini onaylamak amacıyla kullanılan bir güvenlik önlemidir. Kullanıcının kimlik ispatı için hesap şifresi dışında yalnızca onun ulaşabileceği cep telefonuna veya e-postaya sistem tarafından bir şifre gönderilir.',

    smsEpostaInfo:
      'Yukarıdaki seçiminize bağlı olarak SMS veya e-postadan gelecek olan güvenlik kodunu, oturum açmak için şifrenizi girdikten sonra karşınıza çıkan ekrana girmeniz gerekmektedir.',
    myCertificates: 'Sertifikalarım',
    seeAll: 'Tümünü Gör',
    totalNumberofCertificates: 'Toplam Sertifika Sayısı',
    numberOfCertificatesInTheArchive: 'Arşivdeki Sertifika Sayısı',
    numberOfValidCertificates: 'Geçerli Sertifika Sayısı',
    numberOfInvalidCertificates: 'Geçersiz Sertifika Sayısı',
    headerText: 'Profilim',
    transactionType: 'İşlem Tipi',
    transactionHour: 'İşlem Saati',
    transactionIp: 'İşlem IP',
    transactionDesc: 'İşlem Açıklaması',
    transactionText: 'Bu sayfada daha önce gerçekleştirdiğiniz işlemleri görüntüleyebilirsiniz.',
    searchForCertificate: 'Sertifika ara...',
    lastLoginDay: 'Son giriş tarihi: ',
    lastLoginHour: 'Son giriş saati:',
    logout: 'Oturumu Kapat',
    certificateTitle: 'Sertifika Detayı',
    helloText: 'Merhaba',
    // TransactionHistory Start
    operationTime: 'İşlem Zamanı',
    level: 'İşlem Tipi',
    operationAdress: 'İşlem Adresi',
    operationDevice: 'İşlem Cihazı',
    description: 'Açıklama',
    selectTableRow: 'Log Sayısı Seçiniz',
    infolevel: 'Info',
    warnlevel: 'Warn',
    signature: 'İmza',
    otherlevel: 'Diğer',
    selectDate: 'Tarih Seç',
    searchTable: 'Tabloda Ara',
    hello: 'Merhaba',
    lastLogin: 'Son Başarılı giriş:',
    transactionDetail: 'İşlem Detayı',
    transactionStatus: 'İşlem Tipi',
    transactionDate: 'İşlem Tarihi',
    transactionDevice: 'İşlem Cihazı',
    instanceId: 'Örnek Kimlik: ',
    spanId: ' Yayılma Kimliği: ',
    traceId: 'İz Kimliği: ',
    appVersion: 'Uygulama Versiyonu: ',
    emptyTransactionTable: 'İşlem geçmişi bulunamadı',
    emptySignatureTable: 'İmza geçmişi bulunamadı.',
    transactionTable500: 'İşlem geçmişi şu anda görüntülenemiyor. Lütfen daha sonra tekrar deneyiniz.',
    user: 'Kullanıcı',
    name: 'İsim',
    currentPageReportTemplateFL: '{first} - {last} / {totalRecords} Gösteriliyor.',
    signatureHistory: 'İmza Geçmişi',
    fileHash: 'Dosya Kimliği',
    'SAM.NEW_SIGNATURE': 'Yeni imza oluşturuldu.',
    'CMS.NEW_USER_CREATED': 'Kullanıcı hesabı oluşturuldu.',
    'CMS.NEW_CERTIFICATE': 'Yeni sertifika oluşturuldu.',
    'CMS.CERT_STATUS_CHANGED': 'Sertifika durumu değiştirildi. ',
    'KCS.NEW_KEY_PAIR': 'Yeni anahtar çifti oluşturuldu.',
    transaction_login: ' Hesaba başarıyla giriş yapıldı.',
    transaction_logout: ' Hesaptan başarıyla çıkış yapıldı.',
    transaction_login_error: ' Hesaba giriş yapılırken hata oluştu.',
    // TransactionHistory End
    '500title': 'Tekrar Deneyiniz!',
    '500text': 'Ters giden bir şeyler var!',
    app: 'Uygulama',
    otpTitle: 'Bu değişikliği yapmak istediğinizden emin misiniz ?',
    cancel: 'İptal',
    save: 'Kaydet',
    message: 'Mesaj',
    LOGIN: 'Giriş',
    LOGOUT: 'Çıkış',
    AUDIT: 'Denetim',
    UPDATE: 'Güncelleme',
    LOGIN_ERROR: 'Hatalı Giriş',
    UPDATE_PASSWORD: 'Parola Güncelleme',
    CREATE: 'Oluşturma',
    UPDATE_PASSWORD_ERROR: 'Parola Güncelleme Hatası',
  },
};

export default TR;
