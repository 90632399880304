import { createSlice } from '@reduxjs/toolkit';

export const isMobileSize = createSlice({
  name: 'isMobileSize',
  initialState: {
    isMobileSize: null,
  },
  reducers: {
    changeIsMobileSize: (state, action) => {
      state.isMobileSize = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeIsMobileSize } = isMobileSize.actions;

export default isMobileSize.reducer;
