const alertType = {
  error: {
    icon: 'error',
    color: '#EF4444',
    background: '#FEF8F8',
    iconSVG: "<svg height='24' width='24'><use href='#toast-alert'></use></svg>",
  },
  success: {
    icon: 'success',
    color: '#22C55E',
    background: '#F6FDF9',
    iconSVG: "<svg height='24' width='24'><use href='#toast-check'></use></svg>",
  },
  warning: {
    icon: 'warning',
    color: '#F59E0B',
    background: '#FFFBF5',
    iconSVG: "<svg height='24' width='24'><use href='#toast-warning'></use></svg>",
  },
  info: {
    icon: 'info',
    color: '#0EA5E9',
    background: '#F5FBFE',
    iconSVG: "<svg height='24' width='24'><use href='#toast-info'></use></svg>",
  },
  verificationInfo: {
    icon: 'warning',
    color: '#0EA5E9',
    background: '#F5FBFE',
    iconSVG: "<svg height='60' width='60'><use href='#verification-info'></use></svg>",
  },
};

export default alertType;
