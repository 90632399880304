import { MenuType } from 'assets/SideMenu/Menu';
import getMenu from 'common/Menu';
import moment from 'moment';
import { Tooltip } from 'primereact/tooltip';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

function SideMenu() {
  const [sideMenuItem, setSideMenuItem] = useState([]);
  const date = new Date();
  const sidemenuAction = (event) => {
    const closeSideMenu = localStorage.getItem('isDesktopSize');
    const closeOpenSideMenu = !checkSize(closeSideMenu);

    localStorage.setItem('isDesktopSize', closeOpenSideMenu);
    setClass(closeOpenSideMenu);
    event.preventDefault();
    if (document.getElementById('layoutSidenav_nav').classList.contains('sidenav-menu')) {
      document.getElementById('layoutSidenav_nav').classList.remove('sidenav-menu');
      document.getElementById('layoutSidenav_nav').classList.add('sidenav-menu-close');
      document.getElementById('sidenavAccordion').classList.remove('sidenavAccordion');
    } else {
      document.getElementById('layoutSidenav_nav').classList.add('sidenav-menu');
      document.getElementById('layoutSidenav_nav').classList.remove('sidenav-menu-close');
      document.getElementById('sidenavAccordion').classList.add('sidenavAccordion');
    }
  };
  const [showClass, setClass] = useState(true);

  useEffect(() => {
    if (document.getElementById('sidenavAccordion').classList.contains('sidenavAccordion') && window.innerWidth < 992) {
      document.getElementById('sidenavAccordion').classList.remove('sidenavAccordion');
    } else if (!document.getElementById('sidenavAccordion').classList.contains('sidenavAccordion') && window.innerWidth > 992) {
      document.getElementById('sidenavAccordion').classList.add('sidenavAccordion');
    }
    const isActive = localStorage.getItem('isDesktopSize');

    setClass(checkSize(isActive));
  }, [localStorage.getItem('isDesktopSize')]);

  const checkSize = (value) => {
    if (value === 'false') {
      return false;
    } else {
      return true;
    }
  };
  const getMenuList = () => {
    const menu = getMenu();

    setSideMenuItem(menu);
  };

  useEffect(() => {
    getMenuList();
  }, [useSelector((state) => state.ModuleLanguage.language)]);

  const closeSideMenu = () => {
    if (window.innerWidth < 992) {
      document.getElementById('sidebarToggle').click();
    }
  };

  return (
    <div id="layoutSidenav_nav" className={` ${showClass ? 'sidenav-menu' : 'sidenav-menu-close'}`}>
      <nav className="sb-sidenav accordion sidenav-menu-border  sidemenu-border" id="sidenavAccordion">
        <div className="sb-sidenav-menu">
          <div className="nav">
            {sideMenuItem.map((menu, i) => (
              <div key={i}>
                {showClass ? (
                  <>
                    {(menu.type === MenuType.LOGO && (
                      <div key={i} className="sb-sidenav-menu-logo">
                        <svg className="svg-logo">
                          <use href={'#' + menu.iconClass} />
                        </svg>
                      </div>
                    )) ||
                      (menu.type === MenuType.OPEN && (
                        <div key={i} className="sb-sidenav-menu-close">
                          <div id="openCloseIcon">
                            {' '}
                            <svg id="sidebarToggle" onClick={sidemenuAction} className="svg-open-close">
                              <use href={'#' + menu.iconClass} />
                            </svg>
                          </div>
                        </div>
                      )) ||
                      (menu.type === MenuType.HEADING && (
                        <div key={i} className="sb-sidenav-menu-heading">
                          <span className="home-text">{menu.text}</span>
                        </div>
                      )) ||
                      (menu.type === MenuType.ROUTE && (
                        <NavLink onClick={closeSideMenu} key={i} className="nav-link" to={menu.value}>
                          <div className="route-link d-flex ">
                            <div className="sb-nav-link-icon">
                              <svg className="svg-route">
                                <use href={'#' + menu.iconClass} />
                              </svg>
                            </div>
                            <span className="menu-text"> {menu.text}</span>
                          </div>
                        </NavLink>
                      )) ||
                      (menu.type === MenuType.PARENT && (
                        <div>
                          <div
                            key={i}
                            data-bs-target={`#collapse${menu.value}`}
                            aria-controls={`collapse${menu.value}`}
                            className="nav-link collapsed btn"
                            target="_blank"
                            data-bs-toggle="collapse"
                            aria-expanded="false"
                            rel="noreferrer"
                          >
                            <div className="route-link d-flex">
                              <svg className="svg-route-parent">
                                <use href={'#' + menu.iconClass} />
                              </svg>
                              <span className="menu-text"> {menu.text}</span>
                              {/* <div className="sb-sidenav-collapse-arrow">
                                <i className="fas fa-angle-down" />
                              </div> */}
                            </div>
                          </div>
                          {/* alt taga kapanması isterse verilecek id id={`collapse${menu.value}`} */}
                          <div key={`accordion_${i}`} className="collapse show" aria-labelledby={`heading${i}`} data-bs-parent="#sidenavAccordion">
                            {menu.children.map((child, j) => (
                              <nav key={j} id={`sidenavAccordion${menu.value}`} className="sb-sidenav-menu-nested nav accordion">
                                {child.type === MenuType.ROUTE && (
                                  <NavLink onClick={closeSideMenu} to={child.value} className="nav-link-child">
                                    <div className="d-flex ms-1 child-nav">
                                      <div className="sb-nav-link-icon">
                                        <div className="line" />
                                        <svg className="svg-child">
                                          <use className="svg-use-child" href={'#' + child.iconClass} />
                                        </svg>
                                      </div>
                                      <div className="child-text">{child.text}</div>
                                    </div>
                                  </NavLink>
                                )}
                              </nav>
                            ))}
                          </div>
                        </div>
                      )) ||
                      (menu.type === MenuType.PARENT && (
                        <div>
                          <div
                            key={i}
                            data-bs-target={`#collapse${menu.value}`}
                            aria-controls={`collapse${menu.value}`}
                            className="nav-link collapsed btn"
                            target="_blank"
                            data-bs-toggle="collapse"
                            aria-expanded="false"
                            rel="noreferrer"
                          >
                            <div className="route-link d-flex">
                              <svg className="svg-route-parent">
                                <use href={'#' + menu.iconClass} />
                              </svg>
                              <span className="menu-text"> {menu.text}</span>
                              <div className="sb-sidenav-collapse-arrow">
                                <i className="fas fa-angle-down" />
                              </div>
                            </div>
                          </div>
                          <div id={`collapse${menu.value}`} key={`accordion_${i}`} className="collapse" aria-labelledby={`heading${i}`} data-bs-parent="#sidenavAccordion">
                            {menu.children.map((child, j) => (
                              <nav key={j} id={`sidenavAccordion${menu.value}`} className="sb-sidenav-menu-nested nav accordion">
                                {child.type === MenuType.ROUTE && (
                                  <NavLink onClick={closeSideMenu} to={child.value} className="nav-link-child">
                                    <div className="d-flex ms-1 child-nav">
                                      <div className="sb-nav-link-icon">
                                        <div className="line" />
                                        <svg className="svg-child">
                                          <use className="svg-use-child" href={'#' + child.iconClass} />
                                        </svg>
                                      </div>
                                      <div className="child-text">{child.text}</div>
                                    </div>
                                  </NavLink>
                                )}
                              </nav>
                            ))}
                          </div>
                        </div>
                      )) ||
                      (menu.type === MenuType.EXT_LINK && (
                        <a key={i} target="_blank" className="nav-link" href={menu.value} rel="noreferrer">
                          <div className="route-link d-flex ">
                            <div className="sb-nav-link-icon">
                              <svg className="svg-route">
                                <use href={'#' + menu.iconClass} />
                              </svg>
                            </div>
                            <span className="menu-text"> {menu.text}</span>
                          </div>
                        </a>
                      ))}
                  </>
                ) : (
                  <>
                    {(menu.type === MenuType.LOGO && (
                      <div key={i} className="sb-sidenav-menu-logo">
                        <svg className="svg-logo">
                          <use href={'#' + menu.iconClassClose} />
                        </svg>
                      </div>
                    )) ||
                      (menu.type === MenuType.OPEN && (
                        <div key={i} className="sb-sidenav-menu-open">
                          <div>
                            {' '}
                            <svg id="sidebarToggle" onClick={sidemenuAction} className="svg-open-close">
                              <use href={'#' + menu.iconClassClose} />
                            </svg>
                          </div>
                        </div>
                      )) ||
                      (menu.type === MenuType.ROUTE && (
                        <NavLink onClick={closeSideMenu} key={i} className=" nav-link-close" to={menu.value}>
                          <div className="route-link-close d-flex justify-content-center align-items-center">
                            <Tooltip target=".sb-nav-link-icon" />
                            <div className="sb-nav-link-icon" data-pr-tooltip={menu.text} data-pr-my="left+10 center">
                              <svg className="svg-route">
                                <use href={'#' + menu.iconClass} />
                              </svg>
                            </div>
                          </div>
                        </NavLink>
                      )) ||
                      (menu.type === MenuType.PARENT && (
                        <div>
                          <Tooltip target=".route-link-close" />
                          <a
                            key={i}
                            href="true"
                            data-bs-target={`#collapse${menu.value}`}
                            aria-controls={`collapse${menu.value}`}
                            className="nav-link-close collapsed"
                            target="_blank"
                            data-bs-toggle="collapse"
                            aria-expanded="false"
                            rel="noreferrer"
                          >
                            <div data-pr-tooltip={menu.text} data-pr-my="left+10 center" className="route-link-close d-flex">
                              <svg className="svg-route-parent-close">
                                <use href={'#' + menu.iconClass} />
                              </svg>

                              <div className="sb-sidenav-collapse-arrow" />
                            </div>
                          </a>
                          <div id={`collapse${menu.value}`} key={`accordion_${i}`} className="collapse" aria-labelledby={`heading${i}`} data-bs-parent="#sidenavAccordion">
                            {menu.children.map((child, j) => (
                              <nav key={j} id={`sidenavAccordion${menu.value}`} className="sb-sidenav-menu-nested nav accordion">
                                {child.type === MenuType.ROUTE && (
                                  <NavLink onClick={closeSideMenu} to={child.value} className="nav-link-child-close">
                                    <Tooltip target=".svg-child" />
                                    <div className="line" />
                                    <div>
                                      <svg data-pr-tooltip={child.text} data-pr-my="left center-5" className="svg-child">
                                        <use className="svg-use-child" href={'#' + child.iconClass} />
                                      </svg>
                                    </div>
                                  </NavLink>
                                )}
                              </nav>
                            ))}
                          </div>
                        </div>
                      )) ||
                      (menu.type === MenuType.EXT_LINK && (
                        <a key={i} className=" nav-link-close" href={menu.value}>
                          <div className="route-link-close d-flex justify-content-center align-items-center">
                            <Tooltip target=".sb-nav-link-icon" />
                            <div className="sb-nav-link-icon" data-pr-tooltip={menu.text} data-pr-my="left+10 center">
                              <svg className="svg-route">
                                <use href={'#' + menu.iconClass} />
                              </svg>
                            </div>
                          </div>
                        </a>
                      ))}
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="d-flex justify-content-center mb-3">
          {showClass ? <span className="copyright">MA3 2021- {moment(date).format('YYYY')}</span> : <span className="copyright">MA3</span>}
        </div>
      </nav>
    </div>
  );
}

export default SideMenu;
