import { MenuType } from 'assets/SideMenu/Menu';
import i18n from 'i18next';

export default function getMenu() {
  let SideMenuItem = [
    {
      type: MenuType.LOGO,
      iconClass: 'logo',
      iconClassClose: 'clouds-close',
      iconClass2: 'hamburger-menu',
    },
    {
      type: MenuType.OPEN,
      iconClass: 'close',
      iconClassClose: 'open',
    },
    {
      type: MenuType.HEADING,
      text: i18n.t('menu'),
    },
    {
      type: MenuType.ROUTE,
      iconClass: 'profile',
      text: i18n.t('profile'),
      value: '/',
    },
    {
      type: MenuType.ROUTE,
      iconClass: 'certificate',
      text: i18n.t('certificate'),
      value: '/certificates',
    },
    {
      type: MenuType.ROUTE,
      iconClass: 'transaction-history',
      text: i18n.t('transactionHistory'),
      value: '/transaction-history',
    },
  ];

  return SideMenuItem;
}
