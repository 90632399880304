import { Message } from 'primereact/message';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { customUrlService } from 'Services/AxiosServices';

import DialogCertificates from './DialogCertificates';

function Certificates(params) {
  const { t } = useTranslation();
  const [showDialog, setShowDialog] = useState(false);
  const [selectCert, setSelectCert] = useState('');
  const [certStatus, setCertStatus] = useState('');
  const [credentialInfoList, setCredentialInfoList] = useState([]);
  const setCert = (item, status) => {
    setSelectCert(item);
    setCertStatus(status);
    setShowDialog(true);
  };
  const closeDialog = () => {
    setShowDialog(false);
  };

  useEffect(() => {
    customUrlService(window.location.origin + '/config.json', null, false, null, null).then((res) => {
      customUrlService(
        res.data.certificate + '/list',
        'POST',

        {
          credentialInfo: true,
          certificates: 'chain',
          certInfo: true,
          onlyValid: false,
        },

        {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${params.keycloak.token}`,
        },
      ).then((resp) => {
        resp.data.credentialInfoList && setCredentialInfoList(resp.data.credentialInfoList);
      });
    });
  }, []);

  return (
    <>
      <div className="my-profile-card">
        <span className="transaction-history">{t('certificate')}</span>
        <span className="transaction-history-text">{t('certificateSubTitle')}</span>
      </div>
      <DialogCertificates show={showDialog} certificate={selectCert} status={certStatus} dialogClose={closeDialog} />
      <div className="row mt-3">
        {(credentialInfoList.length > 0 &&
          credentialInfoList.map((item) => (
            <div key={item.credentialId} className="col-md-6 col-lg-3 col-sm-12 mb-3">
              <div id="certItem" className="cert-item d-flex">
                <button className="certDetailButton" onClick={() => setCert(item.certificateInfo.certificates[0], item.certificateInfo.status)} />
                <div
                  className={
                    item.certificateInfo.status == 'VALID' ? 'cert-status valid align-self-center text-white' : 'cert-status invalid align-self-center text-white invalid-cert'
                  }
                >
                  <span id="certStatusText">{item.certificateInfo.status == 'VALID' ? 'Geçerli' : 'Geçersiz'}</span>
                </div>
                <div className="cert-select-body d-flex flex-column align-self-center">
                  <h4 id="certSubjectText" className="mb-0 fw-bold">
                    {
                      item.certificateInfo.subjectDN
                        .split(',')
                        .map((x) => x.split('='))
                        .find((e) => e[0] == 'CN')[1]
                    }
                  </h4>
                  <h6 id="certIssuerText" className="cert-issuer mb-0">
                    {
                      item.certificateInfo.issuerDN
                        .split(',')
                        .map((x) => x.split('='))
                        .find((e) => e[0] == 'CN')[1]
                    }
                  </h6>
                  <p id="certElit" className="cert-rsa mb-0">
                    Elit
                  </p>
                </div>
              </div>
            </div>
          ))) || <Message severity="error" text={t('500text')} />}
      </div>
    </>
  );
}

export default Certificates;
