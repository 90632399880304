import EN from 'assets/i18n/en';
import TR from 'assets/i18n/tr';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

// "Inline" English and Arabic translations.
// We can localize to any language and any number of languages.

const resources = {
  'tr-TR': TR,
  'en-US': EN,
  tr: TR,
  en: EN,
};

i18next.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('language'),
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
