import './MyProfile.css';
import 'assets/css/prime-overrides.css';

import SignatureHistory from 'Pages/MyProfile/SignatureHistory/SignatureHistory';
import { Card } from 'primereact/card';
import { RadioButton } from 'primereact/radiobutton';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { popup } from 'Services/AlertServices.js';
import { axiosServicesPut, customUrlService } from 'Services/AxiosServices';
import JwtParser from 'Services/JwtParser';

import MyCertificates from './MyCertificates/MyCertificates';

function MyProfile(params) {
  const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState();
  const [otpPreference, setOtpPreference] = useState();
  const [accountUserName, setAccountUserName] = useState();
  const [accountUserMail, setAccountUserMail] = useState();
  let [url, setUrl] = useState();
  const confirmVerification = (e) => {
    popup('verificationInfo', t('otpTitle'), false, t('save'), t('cancel'), '(timer = 0)').then((res) => {
      if (res === true) {
        const tokenPars = JwtParser(params.keycloak.token);

        axiosServicesPut('/api/users/' + tokenPars.sub + '/sac', { type: e.value, realm: 'Elit' }, false, 'Başarılı şekilde değişiklik yapıldı.', null).then(() => {
          refreshIdToken();
        });
      }
    });
  };
  const refreshIdToken = () => {
    params.keycloak
      .updateToken(2000)
      .then((refreshed) => {
        if (refreshed) {
          otpFunc();
        }
      })
      .catch(() => {
        console.error('ID token refresh failed');
      });
  };

  customUrlService(`${window.location.origin}/config.json`, null, false, null, null).then((res) => {
    setUrl(`${res.data.auth}/realms/Elit/protocol/openid-connect/auth?response_type=code&client_id=elit-account&redirect_uri=${window.location.origin}&kc_action=UPDATE_PASSWORD&prompt=login`);
  });

  useEffect(() => {
    const idTokenParser = JwtParser(params.keycloak.idToken);

    setAccountUserName(idTokenParser.name);
    setAccountUserMail(idTokenParser.email);
    setPhoneNumber(idTokenParser.phone_number);

    otpFunc();
  }, []);
  const otpFunc = () => {
    const idTokenParser = JwtParser(params.keycloak.idToken);

    setPhoneNumber(idTokenParser.phone_number);
    setOtpPreference(idTokenParser.otp_pref);
  };

  return (
    <div>
      <div className="my-profile-card">
        <span className="transaction-history">{t('profile')}</span>
        <span className="transaction-history-text"> {t('transactionHistoryText')}</span>
      </div>

      <div className="row m-0">
        <Card className="my-personal-informations-card col-xl">
          <div className="d-flex justify-content-between">
            <span className="my-personal-informations-title"> {t('myPersonalInformations')} </span>
            <a href={url} target="blank" rel="noreferrer" className="change-password-text">
              {t('changePassword')}
            </a>
          </div>
          <div className="personal-information ">
            <div className="d-flex flex-column justify-content-between" style={{ marginRight: ' 36px' }}>
              <div className="d-flex flex-column personal-information-text">
                <span className="personal-label">{t('nameSurname')}</span>
                <span className="personal-text">{accountUserName}</span>
              </div>
              <div className="d-flex flex-column personal-information-text">
                <span className="personal-label">{t('phoneNumber')}</span>
                <span className="personal-text">{phoneNumber}</span>
              </div>
              <div className="d-flex flex-column personal-information-text">
                <span className="personal-label">{t('ePosta')}</span>
                <span className="personal-text">{accountUserMail}</span>
              </div>
            </div>

            <div className="d-flex flex-column personal-information-text">
              <span className="personal-label"> {t('twoFactorAuthenticationPreference')} </span>
              <span className="two-factor-authentication-text">{t('twoFactorAuthenticationPreferenceText')}</span>

              <div className="d-flex" style={{ margin: ' 16px 0' }}>
                <div>
                  <RadioButton inputId="EMAIL" name="EMAIL" value="EMAIL" onChange={confirmVerification} checked={otpPreference === 'EMAIL'} />
                  <label className="radio-button-label" htmlFor="EMAIL">
                    {t('ePosta')}
                  </label>
                </div>
                <div>
                  <RadioButton inputId="SMS" name="SMS" value="SMS" onChange={confirmVerification} checked={otpPreference === 'SMS'} />
                  <label className="radio-button-label" htmlFor="SMS">
                    {t('sms')}
                  </label>
                </div>
                <div>
                  <RadioButton inputId="APP" name="APP" value="APP" onChange={confirmVerification} checked={otpPreference === 'APP'} />
                  <label className="radio-button-label" htmlFor="SMS">
                    {t('app')}
                  </label>
                </div>
              </div>
              <div className="text-area">
                <span>{t('smsEpostaInfo')}</span>
              </div>
            </div>
          </div>
        </Card>
        <MyCertificates keycloak={params.keycloak} />
      </div>
      <SignatureHistory keycloak={params.keycloak} />
    </div>
  );
}

export default MyProfile;
