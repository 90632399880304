/* eslint-disable react/no-unescaped-entities */

import { MenuType } from 'assets/SideMenu/Menu';
import getMenu from 'common/Menu';
import moment from 'moment';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import JwtParser from 'Services/JwtParser';
import { changeIsMobileSize } from 'Store/MobileSize/MobileSize';
import { changeLanguage } from 'Store/ModuleLanguage/ModuleLanguage';

function Header(params) {
  const { t } = useTranslation();
  const [dimensions, setDimensions] = useState({ width: window.innerWidth });
  const dispatch = useDispatch();
  const [displayPosition, setDisplayPosition] = useState(false);
  const [position, setPosition] = useState('center');
  const dialogFuncMap = {
    displayPosition: setDisplayPosition,
  };
  const [lastDay, setLastDay] = useState();
  const [lastHour, setLastHour] = useState();
  const [accountUserName, setAccountUserName] = useState();
  const [avatarName, setAvatarName] = useState();
  const [avatarSurName, setAvatarSurName] = useState();
  const [accountUserMail, setAccountUserMail] = useState();
  const [userId, setUserId] = useState();
  const [checkedLang, setcheckedLang] = useState('Türkçe');
  const [showMobilSideMenu, setShowMobilSideMenu] = useState(true);
  const [sideMenuItem, setSideMenuItem] = useState([]);

  useEffect(() => {
    setAccountUserName(params.keycloak.tokenParsed.name);
    setAccountUserMail(params.keycloak.tokenParsed.email);
    setUserId(params.keycloak.tokenParsed.sub);
    setAvatarName(params.keycloak.tokenParsed.given_name[0]);
    setAvatarSurName(params.keycloak.tokenParsed.family_name[0]);
    let langLocal = localStorage.getItem('language');

    dispatch(changeLanguage(langLocal));

    if (langLocal == 'tr') {
      langLocal = 'tr-TR';
    } else if (langLocal == 'en') {
      langLocal = 'en-US';
    }
    const setLocalLang = langList.find((item) => item.value === langLocal);

    setcheckedLang(setLocalLang.value);
    window.addEventListener('resize', handleResize);
    const parsToken = JwtParser(localStorage.getItem('JWT-Token'));

    setLastDay(moment.unix(parsToken.iat).format('DD/MM/YYYY'));
    setLastHour(moment.unix(parsToken.iat).format('hh:mm'));
  }, []);
  const handleResize = () => {
    setDimensions({ width: window.innerWidth });
  };
  const getMenuList = () => {
    const menu = getMenu();

    setSideMenuItem(menu);
  };

  useEffect(() => {
    getMenuList();
  }, []);

  if (dimensions.width < 425) {
    localStorage.setItem('isMobileSize', true);
    dispatch(changeIsMobileSize(true));
  } else {
    localStorage.setItem('isMobileSize', false);
    dispatch(changeIsMobileSize(false));
  }

  const sidemenuAction = (event) => {
    setShowMobilSideMenu(!showMobilSideMenu);
  };

  useEffect(() => {
    if (document.getElementById('sidenavAccordion').classList.contains('sidenavAccordion') && window.innerWidth < 992) {
      document.getElementById('sidenavAccordion').classList.remove('sidenavAccordion');
    } else if (!document.getElementById('sidenavAccordion').classList.contains('sidenavAccordion') && window.innerWidth > 992) {
      document.getElementById('sidenavAccordion').classList.add('sidenavAccordion');
    }
    const isActive = localStorage.getItem('isDesktopSize');

    setShowMobilSideMenu(checkSize(isActive));
  }, [localStorage.getItem('isDesktopSize')]);

  const checkSize = (value) => value;
  const closeSideMenuFunc = () => {
    setShowMobilSideMenu(true);
  };
  const changeLang = (e) => {
    setcheckedLang(e.value);
    dispatch(changeLanguage(e.value));
    const selectLang = langList.find((item) => item.value === e.value);

    localStorage.setItem('language', selectLang.value);
    i18n.changeLanguage(selectLang.value);
  };
  const langList = [
    { name: 'Türkçe', value: 'tr-TR', icon: 'lang-turkiye' },
    { name: 'English', value: 'en-US', icon: 'lang-english' },
  ];
  const { i18n } = useTranslation();
  const logout = () => {
    localStorage.removeItem('JWT-Token');
    localStorage.removeItem('language');
    params.keycloak.logout();
  };
  const onClickDialogSettings = (name, position) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };
  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };
  const avatar = avatarName + avatarSurName;

  return (
    <div>
      {dimensions.width > 425 ? (
        <div className="d-flex justify-content-between header ">
          <div className="d-flex flex-column  justify-content-center">
            <span className="header-text">
              {t('helloText')}, {accountUserName}
            </span>
            <span className="header-text-content">
              {t('lastLoginDay')} {lastDay} {t('lastLoginHour')} {lastHour}
            </span>
          </div>
          <div className="d-flex account ">
            <div className="d-flex flex-column justify-content-center">
              <span className="account-name">{accountUserName}</span>
              <span className="account-mail">{accountUserMail}</span>
            </div>

            <Avatar label={avatar} onClick={() => onClickDialogSettings('displayPosition', 'top-right')} className="mr-2 avatar" size="large" shape="circle" />
          </div>
        </div>
      ) : (
        <>
          <div className="header-mobile">
            <svg className="hamburger-menu-svg" onClick={sidemenuAction}>
              <use href="#hamburger-menu" />
            </svg>
            <span className="header-mobile-text">{t('headerText')}</span>
            <svg className="settings-svg" onClick={() => onClickDialogSettings('displayPosition', 'top-right')}>
              <use href="#settings" />
            </svg>
          </div>
          <div className={`${showMobilSideMenu ? 'sidenav-menu mobilSideMenuClose' : 'sidenav-menu mobilSideMenuOpen'}`}>
            <nav className="sb-sidenav accordion sidenav-menu-border  sidemenu-border">
              <div className="sb-sidenav-menu">
                <div className="nav">
                  {sideMenuItem.map((menu, i) => (
                    <div key={i}>
                      {(menu.type === MenuType.LOGO && (
                        <div className="mobile-logo-hamburger">
                          <svg className="svg-logo">
                            <use href={'#' + menu.iconClass} />
                          </svg>
                          <svg id="sidebarToggle" onClick={sidemenuAction} className="svg-open-close">
                            <use href={'#' + menu.iconClass2} />
                          </svg>
                        </div>
                      )) ||
                        (menu.type === MenuType.HEADING && (
                          <div key={i} className="sb-sidenav-menu-heading">
                            <span className="home-text">{menu.text}</span>
                          </div>
                        )) ||
                        (menu.type === MenuType.ROUTE && (
                          <NavLink onClick={closeSideMenuFunc} key={i} className="nav-link" to={menu.value}>
                            <div className="route-link d-flex ">
                              <div className="sb-nav-link-icon">
                                <svg className="svg-route">
                                  <use href={'#' + menu.iconClass} />
                                </svg>
                              </div>
                              <span className="menu-text"> {menu.text}</span>
                            </div>
                          </NavLink>
                        )) ||
                        (menu.type === MenuType.ROUTE && (
                          <NavLink onClick={closeSideMenuFunc} key={i} className="nav-link" to={menu.value}>
                            <div className="route-link d-flex ">
                              <div className="sb-nav-link-icon">
                                <svg className="svg-route">
                                  <use href={'#' + menu.iconClass} />
                                </svg>
                              </div>
                              <span className="menu-text"> {menu.text}</span>
                            </div>
                          </NavLink>
                        )) ||
                        (menu.type === MenuType.ROUTE && (
                          <NavLink onClick={closeSideMenuFunc} key={i} className="nav-link" to={menu.value}>
                            <div className="route-link d-flex ">
                              <div className="sb-nav-link-icon">
                                <svg className="svg-route">
                                  <use href={'#' + menu.iconClass} />
                                </svg>
                              </div>
                              <span className="menu-text"> {menu.text}</span>
                            </div>
                          </NavLink>
                        ))}
                    </div>
                  ))}
                </div>
              </div>
            </nav>
          </div>
        </>
      )}
      <Dialog
        headerClassName="p-dialog-header-close-custom p-dialog-header-custom p-dialog-content-custom p-dialog-header-icons-avatar-custom"
        contentClassName=""
        visible={displayPosition}
        position={position}
        modal
        className="settings-dialog"
        onHide={() => onHide('displayPosition')}
        draggable={false}
        resizable={false}
        dismissableMask={true}
      >
        <div className="m-3 d-grid">
          {' '}
          <span className="m-0 settings-title">ELIT ACCOUNT</span>
          <span className="account-name">{accountUserName}</span>
          <span className="account-mail">{accountUserMail}</span>
          <span className="account-sub">{userId}</span>
          <Button className="d-flex  p-button-secondary p-button-text" onClick={logout}>
            <svg className="sign-out-svg">
              <use href="#sign-out" />
            </svg>
            <span className="sign-out">{t('logout')}</span>
          </Button>
          <Dropdown className="change-language" value={checkedLang} options={langList} onChange={changeLang} optionLabel="name" placeholder="Select a City" />
        </div>
      </Dialog>
    </div>
  );
}

export default Header;
