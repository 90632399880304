import './index.css';

import Keycloak from 'keycloak-js';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { customUrlService } from 'Services/AxiosServices';

import App from './App';
import reportWebVitals from './reportWebVitals';
import Store from './Store/Store';

customUrlService(window.location.origin + '/config.json', null, false, null, null).then((res) => {
  const keycloak = new Keycloak({
    url: res.data.auth,
    realm: 'Elit',
    clientId: 'elit-account',
    scope: 'openid',
  });

  keycloak.init({ onLoad: 'check-sso' }).then((authenticated) => {
    if (authenticated) {
      localStorage.setItem('JWT-Token', keycloak.token);
      localStorage.setItem('language', keycloak.idTokenParsed.locale);
      const root = ReactDOM.createRoot(document.getElementById('root'));

      root.render(
        <Provider store={Store}>
          <App keycloak={keycloak} />
        </Provider>,
      );
    } else {
      keycloak.login();
    }
  });
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
