import './MyCertificates.css';

import { Card } from 'primereact/card';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { customUrlService } from 'Services/AxiosServices';

function MyCertificates(params) {
  const [totalCertificates, setTotalCertificates] = useState(0);
  const [invalidCertificates, setInvalidCertificates] = useState(0);
  const [validCertificates, setValidCertificates] = useState(0);
  const [holdCertificates, setHoldCertificates] = useState(0);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    customUrlService(window.location.origin + '/config.json', null, false, null, null).then((res) => {
      customUrlService(
        res.data.certificate + '/list',
        'POST',

        {
          credentialInfo: true,
          certificates: 'chain',
          certInfo: true,
          onlyValid: false,
        },

        {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${params.keycloak.token}`,
        },
      ).then((resp) => {
        setTotalCertificates(resp.data.credentialInfoList.length);
        const valid = resp.data.credentialInfoList.filter((x) => x.certificateInfo.status == 'VALID');
        const hold = resp.data.credentialInfoList.filter((x) => x.certificateInfo.status == 'CERTIFICATE_HOLD');
        const totalInvalid = resp.data.credentialInfoList.length - (valid.length + hold.length);

        setInvalidCertificates(totalInvalid);
        setValidCertificates(valid.length);
        setHoldCertificates(hold.length);
      });
    });
  }, []);
  const seeAll = () => {
    navigate('/certificates');
  };

  return (
    <Card className="my-certificates-card col-xxl-4 col-xl-4">
      <div className="d-flex justify-content-between  mb-3">
        <span className="my-personal-informations-title"> {t('myCertificates')} </span>
        <button className="see-all-certificates" onClick={seeAll}>
          <span className="see-all-text">{t('seeAll')}</span>
        </button>
      </div>

      <div className="d-flex " style={{ gap: '24px' }}>
        <div className="signature-box">
          <span className="box-title">{t('totalNumberofCertificates')}</span>
          <span className="box-label-1">{totalCertificates}</span>
        </div>
        <div className="signature-box text-center">
          <span className="box-title">{t('numberOfCertificatesInTheArchive')}</span>
          <span className="box-label-2">{holdCertificates}</span>
        </div>
      </div>
      <div className="d-flex" style={{ gap: '24px' }}>
        <div className="signature-box ">
          <span className="box-title">{t('numberOfValidCertificates')}</span>
          <span className="box-label-3">{validCertificates}</span>
        </div>
        <div className="signature-box">
          <span className="box-title">{t('numberOfInvalidCertificates')}</span>
          <span className="box-label-4">{invalidCertificates}</span>
        </div>
      </div>
    </Card>
  );
}

export default MyCertificates;
