import moment from 'moment';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';

function SignatureHistoryDialog(params) {
  const { t } = useTranslation();
  const certDialogWidth = () => {
    if (window.innerWidth < 442) {
      return window.innerWidth;
    }
  };

  return (
    <>
      <Dialog
        headerClassName="p-dialog-header-close-custom p-dialog-header-custom p-dialog-content-custom p-dialog-header-icons-custom"
        className="time-stamp-modal"
        onHide={params.closeDialog}
        visible={params.show}
        dismissableMask={true}
        style={{ width: certDialogWidth() + 'px' }}
      >
        <div className="modal-header-time">
          <span className="process-details">{t('transactionDetail')}</span>
        </div>
        <div className="process-modal-content justify-content-between">
          <div className="row">
            <div className="col-sm-4 col-md-4 col-6">
              <div className="d-grid process-detail">
                <span className="process-detail-label">{t('transactionStatus')}</span>
                {params.selectedRow.type}
              </div>
            </div>
            <div className="col-sm-4 col-md-4 col-6">
              <span className="process-detail-label">{t('transactionDate')}</span>
              <span className="process-detail-text">{moment(params.selectedRow.timestamp).format('DD/MM/YYYY HH:mm')}</span>
            </div>
            <div className="col-sm-4 col-md-4 col-6">
              <div className="d-grid process-detail">
                <span className="process-detail-label">{t('transactionDevice')}</span>
                <span className="process-detail-text text-center">
                  {params.selectedRow.detail.agentInfo.browser} - {params.selectedRow.detail.agentInfo.platform} - {params.selectedRow.detail.agentInfo.client}
                </span>
              </div>
            </div>
          </div>
          {params.selectedRow.user && (
            <div className="d-grid">
              <span className="process-detail-label">{t('user')}</span>
              <span className="process-description-text">
                <b>ID</b>: {params.selectedRow.user.id}
              </span>
              <span className="process-description-text">
                <b>{t('name')}</b>: {params.selectedRow.user.name}
              </span>
              <span className="process-description-text">
                <b>Credential ID</b>: {params.selectedRow.user.credentialId}
              </span>
            </div>
          )}
          <div className="d-grid">
            <span className="process-detail-label">{t('transactionDesc')}</span>
            {params.selectedRow.log.message && (
              <div className="mt-2">
                <b> {t('message')}: </b> {t(params.selectedRow.log.message)}
              </div>
            )}
            {params.selectedRow.log.hashInfos && (
              <div className="mt-2">
                <b> DTBS/R:</b> {params.selectedRow.log.hashInfos}
              </div>
            )}
            {params.selectedRow.log.newStatus && (
              <div className="mt-2">
                <b> New Status:</b> {params.selectedRow.log.newStatus}
              </div>
            )}
            {params.selectedRow.log.serialNo && (
              <div className="mt-2">
                <b> Serial No:</b> {params.selectedRow.log.serialNo}
              </div>
            )}
          </div>
          <div className="d-flex flex-column mt-3 detail-opacity">
            {params.selectedRow.detail.traceInfo.instanceId && (
              <div className="mt-2">
                <b> {t('instanceId')}</b> {params.selectedRow.detail.traceInfo.instanceId}
              </div>
            )}
            {params.selectedRow.detail.traceInfo.spanId && (
              <div className="mt-2">
                <b>{t('spanId')}</b> {params.selectedRow.detail.traceInfo.spanId}
              </div>
            )}
            {params.selectedRow.detail.traceInfo.traceId && (
              <div className="mt-2">
                <b> {t('traceId')}</b> {params.selectedRow.detail.traceInfo.traceId}
              </div>
            )}
            {params.selectedRow.detail.traceInfo.appVersion && (
              <div className="mt-2">
                <b> {t('appVersion')}</b> {params.selectedRow.detail.traceInfo.appVersion}
              </div>
            )}
          </div>
        </div>
      </Dialog>{' '}
    </>
  );
}

export default SignatureHistoryDialog;
