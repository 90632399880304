import { PeculiarCertificateViewer } from '@peculiar/certificates-viewer-react';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';

function DialogCertificates(params) {
  const { t } = useTranslation();
  const checkIcon = () => {
    if (params.status == 'VALID') {
      return (
        <div className="d-flex flex-row">
          <div className="">
            <svg className="checked" width={24} height={24}>
              <use href="#checked" />
            </svg>
          </div>
          <div className="ms-4">{t('certificateTitle')}</div>
        </div>
      );
    } else {
      return (
        <div className="d-flex flex-row">
          <div className="">
            <svg className="checked" width={24} height={24}>
              <use href="#unsuccess" />
            </svg>
          </div>
          <div className="ms-4">{t('certificateTitle')}</div>
        </div>
      );
    }
  };
  const certDialogWidth = () => {
    if (window.innerWidth < 442) {
      return window.innerWidth;
    }
  };

  return (
    <Dialog
      visible={params.show}
      style={{ width: certDialogWidth() + 'px', border: '2pecupx solid #82C078' }}
      headerStyle={params.status == 'VALID' ? { borderTop: '5px solid #82C078' } : { borderTop: '5px solid #df4759' }}
      header={checkIcon}
      modal
      className="p-fluid"
      onHide={params.dialogClose}
      dismissableMask={true}
    >
      <PeculiarCertificateViewer certificate={params.certificate} />
    </Dialog>
  );
}

export default DialogCertificates;
