import './Services/i18n';
import './index';
import 'primereact/resources/primereact.min.css'; // core css
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css'; // theme

import Certificates from 'Pages/Certificates/Certificates';
import MyProfile from 'Pages/MyProfile/MyProfile';
import TransactionHistory from 'Pages/TransactionHistory/TransactionHistory';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import Shell from './Shell/Shell';

function App(params) {
  const { i18n } = useTranslation();

  useEffect(() => {
    const lang = localStorage.getItem('language');

    i18n.changeLanguage(lang);
  }, []);

  useIsDesktopSize();

  function testIsDesktop() {
    if (typeof window === 'undefined') {
      return true;
    }

    return window.innerWidth > 992;
  }

  function useIsDesktopSize() {
    // Initialize the desktop size to an accurate value on initial state set
    const [isDesktopSize, setIsDesktopSize] = useState(testIsDesktop);

    useEffect(() => {
      if (typeof window === 'undefined') {
        return;
      }

      function autoResize() {
        setIsDesktopSize(testIsDesktop());
      }

      window.addEventListener('resize', autoResize);

      // This is likely unnecessary, as the initial state should capture
      // the size, however if a resize occurs between initial state set by
      // React and before the event listener is attached, this
      // will just make sure it captures that.
      autoResize();

      // Return a function to disconnect the event listener
      return () => window.removeEventListener('resize', autoResize);
    }, []);
    localStorage.setItem('isDesktopSize', isDesktopSize);

    // return isDesktopSize;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Shell keycloak={params.keycloak} />} exact>
          <Route path="/" element={<MyProfile keycloak={params.keycloak} />} />
          <Route path="/certificates" element={<Certificates keycloak={params.keycloak} />} />
          <Route path="/transaction-history" element={<TransactionHistory keycloak={params.keycloak} />} />
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
