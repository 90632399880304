import SvgLibrary from 'common/SvgLibrary';
import { Outlet } from 'react-router-dom';

import Header from './Header';
import SideMenu from './SideMenu';

function Shell(params) {
  return (
    <div className="sb-nav-fixed">
      <div id="layout-sidenav">
        <div className="content left-sidebar">
          <SideMenu />
        </div>
        <div className="content w-100 h-100">
          <div id="layout-sidenav_content">
            <Header keycloak={params.keycloak} />
            <main className="main-container">
              <SvgLibrary />
              <Outlet />
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Shell;
