import './index.css';

import { localDateTR } from 'common/LocalDate';
import moment from 'moment';
import { addLocale } from 'primereact/api';
import { Calendar } from 'primereact/calendar';
import { Card } from 'primereact/card';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { customUrlService } from 'Services/AxiosServices';
import { v4 as uuidv4 } from 'uuid';

import TransactionHistoryDialog from './TransactionHistoryDialog';

function TransactionHistory() {
  const { t } = useTranslation();
  const [resStatus, setResStatus] = useState(200);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [selectedHistory, setSelectedHistory] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [filterDate, setFilterDate] = useState(null);
  const [selectedRow, setSelectedRow] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [typeList, setTypeList] = useState([]);

  addLocale('tr-TR', localDateTR);

  useEffect(() => {
    let url;

    if (process.env.NODE_ENV == 'development') {
      url = 'https://account.dev.elit.gov.tr/api/audit?size=1000';
    } else {
      url = window.location.origin + '/api/audit?size=1000';
    }
    customUrlService(url, 'GET', null).then((res) => {
      setResStatus(res.status);
      if (res.status != 500) {
        setResStatus(res.status);
        setTransactionHistory(res.data);
        setSelectedHistory(res.data);
        const uniqueTypes = new Set(res.data.map((log) => log.type));
        const arrayOfUniqueTypes = Array.from(uniqueTypes);

        setTypeList(arrayOfUniqueTypes);
      }
    });
  }, []);

  useEffect(() => {
    let typeFiltered = filterList.length > 0 ? transactionHistory.filter((element) => filterList.includes(element.type)) : transactionHistory;

    typeFiltered = filterDate != null ? typeFiltered.filter((x) => moment(x.timestamp).format('DD/MM/YYYY') == moment(filterDate).format('DD/MM/YYYY')) : typeFiltered;

    setSelectedHistory(typeFiltered);
  }, [filterList, filterDate]);

  const addFilter = (e, type) => {
    if (e.checked) {
      setFilterList([...filterList, type]);
    } else {
      setFilterList(filterList.filter((item) => item !== type));
    }
  };
  const filterItem = (value) => <span>{t(value)}</span>;
  const tableHeader = () => (
    <>
      <div className="row justify-content-between">
        <div className="col-md-4 col-sm-12">
          <span className="p-input-icon-left w-100">
            <i className="pi pi-search" />
            <InputText className="w-100" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('searchTable')} />
          </span>
        </div>
        <div className="col-md-4 offset-md-4 col-sm-12 d-flex justify-content-end position-relative">
          {filterDate && (
            <svg className="transaction-date-svg" onClick={clearDate}>
              <use href="#search-close" />
            </svg>
          )}
          <Calendar
            className="w-100"
            onClearButtonClick={(e) => setFilterDate(e.target.value)}
            placeholder={t('selectDate')}
            id="icon"
            dateFormat="dd/mm/yy"
            value={filterDate}
            onChange={(e) => setFilterDate(e.value)}
            showIcon
            locale={localStorage.getItem('lang') == 'tr-TR' ? 'tr-TR' : null}
          />
        </div>
      </div>
      <div className="d-flex justify-content-between" style={{ margin: ' 16px 0' }}>
        <div className="d-flex mt-3">
          {typeList.map((item) => (
            <div key={uuidv4()}>
              <Checkbox inputId={item} checked={filterList.includes(item)} onChange={(e) => addFilter(e, item)} />
              <label className="radio-button-label ms-1" htmlFor="info">
                {filterItem(item)}
              </label>
            </div>
          ))}
        </div>
      </div>
    </>
  );
  const timestampBody = (rowData) => (
    <div>
      <span>{moment(rowData.timestamp).format('DD/MM/YYYY HH:mm')}</span>
    </div>
  );
  const rowSelect = (e) => {
    setSelectedRow(e.value);
    setShowDialog(true);
  };
  const closeDialog = () => {
    setShowDialog(false);
  };
  const clearDate = () => {
    setFilterDate(null);
  };
  const messageBody = (rowData) => {
    if (rowData.type === 'LOGIN') {
      return <span>{t('transaction_login')}</span>;
    } else if (rowData.type === 'LOGOUT') {
      return <span>{t('transaction_logout')}</span>;
    } else if (rowData.type === 'LOGIN_ERROR') {
      return <span>{t('transaction_login_error')}</span>;
    } else {
      return <span>{t(rowData.log.message)}</span>;
    }
  };

  return (
    <>
      <Card className="transaction-history-card">
        <div className="d-flex justify-content-between">
          <span className="my-personal-informations-title"> {t('transactionHistory')} </span>
        </div>
        <div>
          <DataTable
            selectionMode="single"
            value={selectedHistory}
            paginator
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate={t('currentPageReportTemplateFL')}
            className="p-datatable-customers"
            rows={10}
            dataKey="id"
            filterDisplay="menu"
            responsiveLayout="scroll"
            globalFilter={globalFilter}
            header={tableHeader}
            onSelectionChange={(e) => rowSelect(e)}
            emptyMessage={resStatus === 500 ? t('transactionTable500') : t('emptyTransactionTable')}
            rowsPerPageOptions={[10, 20, 30]}
          >
            <Column sortable body={timestampBody} field="timestamp" header={t('operationTime')} headerStyle={{ width: '25%' }} />
            <Column field="type" header={t('level')} headerStyle={{ width: '25%' }} />
            <Column field="ip" header={t('operationAdress')} headerStyle={{ width: '25%' }} />
            {/* <Column field="detail.agentInfo.browser" header={t('operationDevice')} style={{ whiteSpace: 'nowrap' }} /> */}
            <Column field="log.message" body={messageBody} header={t('description')} headerStyle={{ width: '25%' }} />
          </DataTable>
        </div>
      </Card>
      {showDialog && <TransactionHistoryDialog show={showDialog} closeDialog={closeDialog} selectedRow={selectedRow} />}
    </>
  );
}

export default TransactionHistory;
